import { useEffect, useState } from 'react';
import { Channel, DefaultGenerics, StreamChat } from 'stream-chat';
import { Channel as ChannelUI, Chat, Streami18n } from 'stream-chat-react';
import { EmojiPicker } from 'stream-chat-react/emojis';
import data from '@emoji-mart/data';
import { init, SearchIndex } from 'emoji-mart';
import 'stream-chat-react/dist/css/v2/index.css';
import './styles/index.css';

import daLang from './locales/da.json';
import deLang from './locales/de.json';
import enLang from './locales/en.json';
import esLang from './locales/es.json';
import fiLang from './locales/fi.json';
import frLang from './locales/fr.json';
import nbLang from './locales/nb.json';
import svLang from './locales/sv.json';

import {
  ChannelInner,
  MessagingSidebar,
  MessagingThreadHeader,
  SendButton,
} from './components';

import { GiphyContextProvider, useThemeContext } from './context';
import { useConnectUser, useChecklist, useMobileView, useUpdateAppHeightOnResize } from './hooks';
import Interop from './interfaces/Interop';
import DateSeparatorCustom from './components/DateSeparator/DateSeparatorCustom';
import { getChannelListOptions } from './channelListOptions';
import { ApplicationColor } from './models/ApplicationColor';
import { ChatPageNavigation } from './models/ChatPageNavigation';
import { ChatPageType } from './enums/ChatPageType';
import { ClientCredential } from './models/ClientCredential';
import HybridWebView from './hybridWebView/HybridWebView';
import { ModuleType } from './enums/ModuleType';
import { useChannelContext } from '../src/components/ChannelContext/ChannelContext'; // Import your context
import { BlockUnblockProvider } from '../src/context/BlockUnblock';
import { AlertContextProvider } from '../src/context/AlertContext';

init({ data });

type AppProps = {
  apiKey: string;
  credentials: ClientCredential;
  targetOrigin: string;
  colors: ApplicationColor;
  pageType: ChatPageNavigation;
  fontSize: Number;
  platformTypes: string;
  interop: Interop;
  language: string
};

const WrappedEmojiPicker = () => {
  const { theme } = useThemeContext();
  return <EmojiPicker pickerProps={{ theme }} />;
};

function setColors(colors: ApplicationColor) {
  document.documentElement.style.setProperty('--app_background-color', colors.TopNavBackgroundColor);
  document.documentElement.style.setProperty('--app_forgrond-color', colors.TopNavForegroundColor);
}

function setFontSize(size: Number) {
  document.documentElement.style.setProperty('--text-size', `${size}%`);
}

async function createChannel(
  chatClient: StreamChat<DefaultGenerics>,
  myUserId: string,
  receiverUserId: string,
  teamId: string,
  eventId: number,
  ChannelHashId: string,
  type: string = "messaging"
) {
  const channel = chatClient.channel(type, ChannelHashId, {
    members: [myUserId, receiverUserId],
    team: teamId, eventId: eventId
  });
  await channel.watch();
  return channel;
}

async function getChannel(
  chatClient: StreamChat<DefaultGenerics>,
  entityType: ModuleType,
  entityId: string,
  eventId: number,
) {

  if (entityType == ModuleType.Push) {
    const channelFilter = { eventId: eventId };
    const channeldata = await chatClient.queryChannels(channelFilter);
    let channel = channeldata.filter(ele => {
      return ele.cid === entityId
    });
    return channel[0];
  } else {
    var channel = null;
    const publicwithoutChannelFilter = { type: 'public_without_membership', eventId: eventId };
    const publicwithoutChannels = await chatClient.queryChannels(publicwithoutChannelFilter, undefined, { state: false, watch: false });
    if (publicwithoutChannels.length > 0) {
      for (let i = 0; i < publicwithoutChannels.length; i++) {
        if (publicwithoutChannels[i] !== null && publicwithoutChannels[i] !== undefined) {
          var obj = publicwithoutChannels[i].data!.details;
          const parsedObject = JSON.parse(obj as string);
          if (parsedObject.EntityType == "Program" && parsedObject.EntityId == entityId) {
            channel = publicwithoutChannels[i];
            break;
          }
        }
      }
    }
    return channel;
  }
}



const App = (props: AppProps) => {
  const { apiKey, credentials, targetOrigin, colors, pageType, interop, fontSize, platformTypes, language } = props;
  const channelListOptions = getChannelListOptions(credentials.UserId, credentials.EventId);
  const [isCreating, setIsCreating] = useState(false);
  const [channel, setChannel] = useState<Channel<DefaultGenerics> | null>(null);

  let userId = credentials.UserId;
  let userToken = credentials.UserToken;
  let teamId = credentials.TeamId;
  const eventId = credentials.EventId;

  const chatClient = useConnectUser(apiKey, { id: userId, eventId: eventId }, userToken);

  const toggleMobile = useMobileView();
  const { themeClassName } = useThemeContext();
  const { selectedChannel, setSelectedChannel } = useChannelContext();

  let languageCode: any;

  switch (language) {
    case 'en':
      languageCode = enLang;
      break;
    case 'da':
      languageCode = daLang;
      break;
    case 'de':
      languageCode = deLang;
      break;
    case 'es':
      languageCode = esLang;
      break;
    case 'fi':
      languageCode = fiLang;
      break;
    case 'fr':
      languageCode = frLang;
      break;
    case 'nb':
      languageCode = nbLang;
      break;
    case 'sv':
      languageCode = svLang;
      break;
    default:
      languageCode = enLang;
  }

  useChecklist(chatClient, targetOrigin);
  useUpdateAppHeightOnResize();
  setColors(colors);
  useEffect(() => {
    languageCode = language || enLang;
    setFontSize(fontSize);
  }, []);

  if (!chatClient) {
    return null;
  }

  if (pageType.ChatPageType == ChatPageType.ChannelDetail) {
    pageType.ChatPageType = ChatPageType.ChannelListing;
    if (pageType.EntityType == ModuleType.Participant) {
      createChannel(chatClient!, userId, pageType.EntityId, teamId, eventId, pageType.ChannelHashId).then(channel => {
        if (channel.data?.blocked === false) {
          console.log("chan" + channel);
          setChannel(channel);
          setSelectedChannel(channel);
        } else {
          const userConfirmed = window.confirm(languageCode['You have blocked the participant. Do you want to unblock?']);
          if (userConfirmed) {
            chatClient.unBlockUser(pageType.EntityId).then(_ => {
              setChannel(channel);
              setSelectedChannel(channel);
              channel.updatePartial({ set: { frozen: false } });
            });
          }
        }
      });
    } else {
      getChannel(chatClient!, pageType.EntityType, pageType.EntityId, eventId).then(channel => {
        setChannel(channel);
        setSelectedChannel(channel);
      });
    }
  }

  interop.FinishedLoading(true);

  const i18nInstance = new Streami18n({
    translationsForLanguage: languageCode,
  });




  return (
    <BlockUnblockProvider>
      <AlertContextProvider>
      <Chat client={chatClient} i18nInstance={i18nInstance} theme={`messaging ${themeClassName}`}>
        <MessagingSidebar
          channelListOptions={channelListOptions}
          onClick={() => {
            toggleMobile();
            interop.SlidingGesture(false);
            interop.NavigationHandle(false);
          }}
          onCreateChannel={()=>{
            interop.ToogleMenu();
          }
        }
          onPreviewSelect={() => setIsCreating(false)}
          activeChannel={channel}
          channelQueryParams={{ eventId }}
          platformType={platformTypes}
          languageCode={languageCode}
        />
        {selectedChannel ? (
          <ChannelUI
            maxNumberOfFiles={10}
            multipleUploads={true}
            SendButton={SendButton}
            ThreadHeader={(props) => <MessagingThreadHeader {...props} languageCode={languageCode} />}
            EmojiPicker={HybridWebView.IsWebView() ? undefined : WrappedEmojiPicker}
            emojiSearchIndex={HybridWebView.IsWebView() ? undefined : SearchIndex}
            DateSeparator={(props) => <DateSeparatorCustom {...props} languageCode={languageCode} />}
            UnreadMessagesNotification={() => null}
          >
            <GiphyContextProvider>
              <ChannelInner theme={themeClassName}
                toggleMobile={() => {
                  toggleMobile();
                  interop.SlidingGesture(true);
                  if (pageType.EntityId === "-1") {
                    interop.NavigationHandle(false);
                  } else {
                    interop.NavigationHandle(true);
                  }
                  interop.PushHandle("-1");
                }}
                interop={interop}
                platformType={platformTypes}
                languageCode={languageCode}
                pageType={pageType}
              />
            </GiphyContextProvider>
          </ChannelUI>
        ) : (
          <div className='str-chat-text-msg'>{languageCode['Select a participant from list to initiate chat']}</div>
        )}
      </Chat>
      </AlertContextProvider>
    </BlockUnblockProvider>
  );
};

export default App;
