export const MuteChannelIcon = () => (
  <svg width="20"
 height="20" 
 viewBox="0 0 20 20" 
 fill="none" 
 xmlns="http://www.w3.org/2000/svg">

<path d="M12.5727 19.1816C12.4129 19.4572 12.1835 19.6859 11.9075 19.8449C11.6314 20.0038 11.3185 20.0875 11 20.0875C10.6814 20.0875 10.3685 20.0038 10.0925 19.8449C9.81648 19.6859 9.58707 19.4572 9.42725 19.1816"
   strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
<path d="M17.0274 11.9087C16.623 10.4282 16.4302 8.89787 16.4547 7.36328" 
 strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
<path d="M5.78175 5.78125C5.62381 6.29362 5.54413 6.82691 5.54539 7.36307C5.54539 13.7267 2.81812 15.5449 2.81812 15.5449H15.5454" 
 strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
<path d="M16.4545 7.36401C16.4559 6.37588 16.189 5.40592 15.682 4.55773C15.1751 3.70953 14.4473 3.01497 13.5764 2.54822C12.7055 2.08148 11.7241 1.86009 10.7371 1.9077C9.75013 1.95532 8.79463 2.27014 7.97266 2.81855" 
  strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
<path d="M1 1L21 21" 
stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);


