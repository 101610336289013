// Standard methods for HybridWebView

export default class HybridWebView {
  static SendRawMessageToDotNet = function (message) {
    this.SendMessageToDotNet(0, message);
  };

  static SendInvokeMessageToDotNet = function (methodName, paramValues) {
    if (typeof paramValues !== "undefined") {
      if (!Array.isArray(paramValues)) {
        paramValues = [paramValues];
      }
      for (var i = 0; i < paramValues.length; i++) {
        paramValues[i] = JSON.stringify(paramValues[i]);
      }
    }

    this.SendMessageToDotNet(
      1,
      JSON.stringify({ MethodName: methodName, ParamValues: paramValues })
    );
  };

  static SendMessageToDotNet = function (messageType, messageContent) {
    var message = JSON.stringify({
      MessageType: messageType,
      MessageContent: messageContent,
    });

    if (window.chrome && window.chrome.webview) {
      // Windows WebView2
      window.chrome.webview.postMessage(message);
    } else if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.webwindowinterop
    ) {
      // iOS and MacCatalyst WKWebView
      window.webkit.messageHandlers.webwindowinterop.postMessage(message);
    } else {
      // Android WebView
      window.hybridWebViewHost.sendMessage(message);
    }
  };

  static async SendInvokeMessageToDotNetAsync(methodName, paramValues) {
    const body = {
      MethodName: methodName,
    };

    if (typeof paramValues !== "undefined") {
      if (!Array.isArray(paramValues)) {
        paramValues = [paramValues];
      }

      for (var i = 0; i < paramValues.length; i++) {
        paramValues[i] = JSON.stringify(paramValues[i]);
      }

      if (paramValues.length > 0) {
        body.ParamValues = paramValues;
      }
    }

    const message = JSON.stringify(body);

    try {
      // Android web view doesn't support getting the body of a POST request, so we use a GET request instead and pass the body as a query string parameter.
      var requestUrl = `${
        window.location.origin
      }/proxy?__ajax=${encodeURIComponent(message)}`;

      const rawResponse = await fetch(requestUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });
      const response = await rawResponse.json();

      if (response) {
        if (response.IsJson) {
          return JSON.parse(response.Result);
        }

        return response.Result;
      }
    } catch (e) {}

    return null;
  }

  static IsWebView = () => {
    if (typeof window === undefined) { return false };

    let navigator = window.navigator;

    const standalone = navigator.standalone;
    const userAgent = navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);

    return ios ? !standalone && !safari : userAgent.includes('wv');
  }
}
