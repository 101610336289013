import React, { createContext, useContext, useState, ReactNode } from 'react';

interface BlockedUsers {
  [userId: string]: boolean;
}

interface BlockUnblockContextType {
  blockedUsers: BlockedUsers;
  toggleBlock: (userId: string) => void;
  isUserBlocked: (userId: string) => boolean; // New method to check block status
}

const BlockUnblockContext = createContext<BlockUnblockContextType | undefined>(undefined);

export const BlockUnblockProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [blockedUsers, setBlockedUsers] = useState<BlockedUsers>({});
  const toggleBlock = (userId: string) => {
    setBlockedUsers((prev) => ({
      ...prev,
      [userId]: !prev[userId], // Flip the blocked state when toggling
    }));
  };

  const isUserBlocked = (userId: string) => {
    return blockedUsers[userId] || false; // Check if user is blocked
  };
  return (
    <BlockUnblockContext.Provider value={{ blockedUsers, toggleBlock, isUserBlocked }}>
      {children}
    </BlockUnblockContext.Provider>
  );
};

export const useBlockUnblockContext = () => {
  const context = useContext(BlockUnblockContext);
  if (!context) {
    throw new Error('useBlockUnblockContext must be used within a BlockUnblockProvider');
  }
  return context;
};
