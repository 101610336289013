import Interop from "../interfaces/Interop";
import HybridWebView from "../hybridWebView/HybridWebView";
import { ApplicationColor } from "../models/ApplicationColor";
import { ClientCredential } from "../models/ClientCredential";
import { ChatPageNavigation } from "../models/ChatPageNavigation";
import { ChannelDataModel } from "../models/ChannelDataModel";

export default class NativeInterop implements Interop {

    async GetFontSize(): Promise<Number> {
        const value = await HybridWebView.SendInvokeMessageToDotNetAsync("GetFontSize");
        return value;
    }

   async GetPageType(): Promise<ChatPageNavigation> {
        const value = await HybridWebView.SendInvokeMessageToDotNetAsync("GetPageType");
        return JSON.parse(value);
    }

    async GetCredentials(): Promise<ClientCredential> {
        const value = await HybridWebView.SendInvokeMessageToDotNetAsync("GetClientCredential");
        return JSON.parse(value);
    }
    async RefreshToken(): Promise<ClientCredential> {
        const value = await HybridWebView.SendInvokeMessageToDotNetAsync("RefreshToken");
        return JSON.parse(value);
    }
    
    async GetColors(): Promise<ApplicationColor> {
        const value = await HybridWebView.SendInvokeMessageToDotNetAsync("GetApplicationColors");
        return JSON.parse(value);
    }

    async GetLanguage(): Promise<string> {
        const value = await HybridWebView.SendInvokeMessageToDotNetAsync("GetLanguage");
        return value;
    }
        
    FinishedLoading(isFinished: boolean ): void {
        HybridWebView.SendInvokeMessageToDotNetAsync("OnFinishedLoading", isFinished);
    }

    SlidingGesture(enable: boolean): void {
        HybridWebView.SendInvokeMessageToDotNet("SlidingGesture", enable);
    }

    GetUnreadCount(UnreadCount: boolean): void {
        HybridWebView.SendInvokeMessageToDotNet("GetUnreadCount", UnreadCount);
    }

    NavigationHandle(enable: boolean): void {
        HybridWebView.SendInvokeMessageToDotNet("NavigationHandle", enable);
    }
    PushHandle(ReceiverUserId: String): void {
        HybridWebView.SendInvokeMessageToDotNet("PushHandle", ReceiverUserId);
    }
    
    ToogleMenu(): void {
        HybridWebView.SendInvokeMessageToDotNet("ToggleMenu");
    }
    async GetPlatformType(): Promise<string> {
        const value = await HybridWebView.SendInvokeMessageToDotNetAsync("GetPlatformType");
        return (value);
    }
    SendNewMessageUpdate(channelData: ChannelDataModel): void {
        console.log("Message", channelData);
    }
}
