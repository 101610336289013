export class ChannelDataModel {

    LastChannelUpdatedAt: string;
    ChannelType: string;
    IsChannelActive: boolean;
    ChannelId: string;


    constructor(lastChannelUpdatedAt: string, channelType: string,isChannelActive: boolean,channelId: string) {
        this.LastChannelUpdatedAt = lastChannelUpdatedAt;
        this.ChannelType = channelType;
        this.IsChannelActive = isChannelActive;
        this.ChannelId = channelId;
    }
}
