export const UnBlockUserIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 19.9474V16.8421C1 15.7254 1.44361 14.6545 2.23323 13.8648C3.02286 13.0752 4.09383 12.6316 5.21053 12.6316H9.42105"
     strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d="M9.42098 9.42105C11.7464 9.42105 13.6315 7.53594 13.6315 5.21053C13.6315 2.88512 11.7464 1 9.42098 1C7.09557 1 5.21045 2.88512 5.21045 5.21053C5.21045 7.53594 7.09557 9.42105 9.42098 9.42105Z"
       strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d="M21 11L10.6875 21L6 16.4545"
       strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>


);

