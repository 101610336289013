import React, { useCallback, useState, forwardRef, useEffect } from 'react';
import { useMessageContext, useChannelStateContext, ReactionSelectorProps, useChatContext } from 'stream-chat-react';
import { ReactionResponse, GetReactionsAPIResponse } from 'stream-chat';
import { Upvote } from '../../assets/Upvote';
import { UpvoteGrey } from '../../assets/UpvoteGrey';
import "./CustomReactionSelectors.css";
import { StreamChatGenerics } from '../../types';

const CustomReactionSelectors = forwardRef<HTMLDivElement, ReactionSelectorProps<any>>((_, ref) => {
    const {
        message: { own_reactions: ownReactions = [], id: messageId },
    } = useMessageContext('CustomReactionSelector');
    const { channel } = useChannelStateContext('CustomReactionSelector');
    const { client } = useChatContext<StreamChatGenerics>();

    const [reactions, setReactions] = useState<ReactionResponse<any>[]>(ownReactions || []);
    const [upvoteCount, setUpvoteCount] = useState<number>(0);
    const [hasUpvoted, setHasUpvoted] = useState<boolean>(false);

    const fetchReactions = async () => {
        try {
            const response: GetReactionsAPIResponse<any> = await channel.getReactions(messageId, { limit: 10 });
            setReactions(response.reactions);
            const totalUpvotes = response.reactions.filter(r => r.type === 'thumbs_up').length;
            setUpvoteCount(totalUpvotes);

            const userId = client.user?.id;
            if (userId) {
                const userHasUpvoted = response.reactions.some(reaction => reaction.type === 'thumbs_up' && reaction.user.id === userId);
                setHasUpvoted(userHasUpvoted);
            }
        } catch (error) {
            console.error('Error fetching reactions:', error);
        }
    };

    useEffect(() => {
        fetchReactions();

        const handleNewReaction = async (event: { message?: { id: string; } }) => {
            if (event.message?.id === messageId) {
                await fetchReactions();
            }
        };

        channel.on('reaction.new', handleNewReaction);
        channel.on('reaction.deleted', handleNewReaction);

        return () => {
            channel.off('reaction.new', handleNewReaction);
            channel.off('reaction.deleted', handleNewReaction);
        };
    }, [channel, messageId]);

    const handleReaction = useCallback(
        async (reactionType: string, event: React.MouseEvent) => {
            event.preventDefault();
            const userId = client.user?.id;

            const hasReactedWithType = reactions.some((reaction) => 
                reaction.type === reactionType && reaction.user.id === userId
            );

            try {
                if (hasReactedWithType) {
                    await channel.deleteReaction(messageId, reactionType);
                    setReactions((prev) => prev.filter((reaction) => reaction.type !== reactionType));
                    setUpvoteCount((prevCount) => prevCount - 1);
                    setHasUpvoted(false);
                } else {
                    await channel.sendReaction(messageId, { type: reactionType });
                    setReactions((prev) => [...prev, { type: reactionType }]);
                    setUpvoteCount((prevCount) => prevCount + 1);
                    setHasUpvoted(true);
                }
            } catch (error) {
                console.error('Error sending reaction:', error);
            }
        },
        [channel, reactions, messageId]
    );

    // Conditional customEmojis based on hasUpvoted
    const customEmojis = hasUpvoted 
        ? [{ type: 'thumbs_up', image: <Upvote /> }] 
        : [{ type: 'thumbs_up', image: <UpvoteGrey /> }];

    return (
        <div ref={ref}>
            {customEmojis.map((emoji) => (
                <div key={emoji.type} onClick={(e) => handleReaction(emoji.type, e)} className={`upvote-btn ${hasUpvoted ? 'highlighted' : ''}`}>
                    {emoji.image} {upvoteCount}
                </div>
            ))}
        </div>
    );
});

export default CustomReactionSelectors;
