export const CircularUser = () => (

<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45Z" 
fill="#D2D2D2"/>
<path d="M30 30.75V29C30 28.0717 29.6313 27.1815 28.9749 26.5251C28.3185 25.8687 27.4283 25.5 26.5 25.5H19.5C18.5717 25.5 17.6815 25.8687 17.0251 26.5251C16.3687 27.1815 16 28.0717 16 29V30.75"
 stroke="black" strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
<path d="M23 22C24.933 22 26.5 20.433 26.5 18.5C26.5 16.567 24.933 15 23 15C21.067 15 19.5 16.567 19.5 18.5C19.5 20.433 21.067 22 23 22Z" 
stroke="black" strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
<path d="M33.7349 30.7538V29.1153C33.7343 28.3892 33.4926 27.6839 33.0478 27.11C32.603 26.5362 31.9802 26.1263 31.2771 25.9448" 
stroke="black" strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
<path d="M28 16.1138C28.7049 16.2942 29.3297 16.7042 29.7759 17.279C30.222 17.8538 30.4642 18.5607 30.4642 19.2884C30.4642 20.0159 30.222 20.7229 29.7759 21.2977C29.3297 21.8725 28.7049 22.2825 28 22.4629"
 stroke="black" strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
<path d="M12 30.7538V29.1153C12.0006 28.3892 12.2422 27.6839 12.6871 27.11C13.1319 26.5362 13.7547 26.1263 14.4578 25.9448" 
stroke="black"strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
<path d="M17.7346 16.1138C17.0297 16.2942 16.4049 16.7042 15.9588 17.279C15.5126 17.8538 15.2704 18.5607 15.2704 19.2884C15.2704 20.0159 15.5126 20.7229 15.9588 21.2977C16.4049 21.8725 17.0297 22.2825 17.7346 22.4629" 
stroke="black" strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
</svg>
);

