import Interop from "../interfaces/Interop";
import { ModuleType } from "../enums/ModuleType";
import { ApplicationColor } from "../models/ApplicationColor";
import { ChatPageNavigation } from "../models/ChatPageNavigation";
import { ClientCredential } from "../models/ClientCredential";
import { ChannelDataModel } from "../models/ChannelDataModel";
import { ChatPageType } from "../enums/ChatPageType";

export default class WebInterop implements Interop {
    public responseHandlers: Map<string, (value: any) => void> = new Map();

    constructor() {
        this.initMessageListener();
    }

    public initMessageListener() {
        window.addEventListener("message", this.handleMessage);
    }

    FinishedLoading(): void {
        console.log("Finished Loading");
    }
    async GetLanguage(): Promise<string> {
        const languageCode = await this.sendMessageAndWaitForResponse("GetLanguage", String);
        return Promise.resolve(languageCode);
    }
    ToogleMenu(): void {
        console.log("Menu Toggled!");
    }

    SlidingGesture(enable: boolean): void {
        console.log("Sliding Gesture: " + enable);
    }

    GetFontSize(): Promise<Number> {
        return Promise.resolve(100);
    }

    async GetCredentials(): Promise<ClientCredential> {
        const response = await this.sendMessageAndWaitForResponse("GetCredentials", JSON.parse);
        const { UserId, UserToken, TeamId, EventId } = response;
        return new ClientCredential(UserId, UserToken, TeamId, Number.parseInt(EventId));
    }

    async GetColors(): Promise<ApplicationColor> {
        const response = await this.sendMessageAndWaitForResponse("GetColors", JSON.parse);
        const { TopNavBackgroundColor, TopNavForegroundColor } = response;
        return new ApplicationColor(TopNavBackgroundColor, TopNavForegroundColor);
    }

    async GetPageType(): Promise<ChatPageNavigation> {
        const pageTypeResponse = await this.sendMessageAndWaitForResponse("GetPageType", JSON.parse);
        const { ChatPageType, EntityType, EntityId, ChannelHashId, PushMessageId } = pageTypeResponse;
        return new ChatPageNavigation(ChatPageType, EntityType, EntityId, ChannelHashId, PushMessageId);
    }

    async RefreshToken(): Promise<ClientCredential> {
        const response = await this.sendMessageAndWaitForResponse("RefreshToken", JSON.parse);
        const { UserId, UserToken, TeamId, EventId } = response;
        return new ClientCredential(UserId, UserToken, TeamId, Number.parseInt(EventId));
    }

    SendNewMessageUpdate(channelData: ChannelDataModel): void {
        console.log("Message", JSON.stringify(channelData));
        this.sendMessage("SendNewMessageUpdate", JSON.stringify(channelData));
    }

    NavigationHandle(enable: boolean): void {
        console.log("Navigation Handle: " + enable);
    }

    PushHandle(ReceiverUserId: string): void {
        ReceiverUserId = ReceiverUserId.split('-')[1];
        console.log("PushHandle", ReceiverUserId);
        // this.sendMessage("PushHandle", ReceiverUserId);
    }

    public sendMessage(type: string, channelData?: string) {
        const message: { type: string; channelData?: string } = { type };

        if (channelData) {
            // Serialize the channelData object to a string if it's present
            message.channelData = JSON.stringify(channelData);
        }

        window.parent.postMessage(message, "https://web-dev.ventla.io/");
        window.parent.postMessage(message, "https://vueadmin-dev.ventla.io/");
    }
    public sendMessageAndWaitForResponse<T>(type: string, transform: (data: any) => T): Promise<T> {
        return new Promise((resolve, reject) => {
            const handler = (data: any) => {
                try {
                    resolve(transform(data));
                } catch (error) {
                    reject(error);
                } finally {
                    this.responseHandlers.delete(type);
                }
            };
            this.responseHandlers.set(type, handler);
            this.sendMessage(type);
        });
    }

    public handleMessage = (event: MessageEvent) => {
        if (event.origin === "https://web-dev.ventla.io" || event.origin === "https://vueadmin-dev.ventla.io") {
            const { type, data } = event.data;
            const handler = this.responseHandlers.get(type);
            if (handler) {
                handler(data);
            }
        }
    };

    GetPlatformType(): Promise<string> {
        return Promise.resolve("BlazorPWA");
    }
}








// import { ChatPageType } from "../enums/ChatPageType";
// import { ModuleType } from "../enums/ModuleType";
// import Interop from "../interfaces/Interop"
// import { ApplicationColor } from "../models/ApplicationColor";
// import { ChatPageNavigation } from "../models/ChatPageNavigation";
// import { ClientCredential } from "../models/ClientCredential";
// import { ChannelDataModel } from "../models/ChannelDataModel";
// export default class WebInterop implements Interop  {
//     constructor() {
//         console.log("WebInterop");
//     }
//     GetFontSize(): Promise<Number> {
//         return Promise.resolve(100);
//     }
//     GetLanguage(): Promise<string> {
//         return Promise.resolve("en");
//     }
//     GetPageType(): Promise<ChatPageNavigation> {
//         const chatPageType = ChatPageType.ChannelDetail;
//         const entityType = ModuleType.Push;
//         const entityId = "messaging:2598_131858_370908_370994";
//        //const ChannelHashId =  "2459_131506_341681_368450";
//         const ChannelHashId =  "";
//         const PushMessageId =  "1d15f7a7-627c-4fef-8714-495ced210596";
//         const pageNavigation = new ChatPageNavigation(chatPageType, entityType,entityId,ChannelHashId,PushMessageId);
//         return Promise.resolve(pageNavigation);
//     }
//     GetCredentials(): Promise<ClientCredential> {
//         const user =  "2598-371159";
//         const userToken ="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiMjU5OC0zNzExNTkiLCJleHAiOjE3MzQ0MTQ4MjksImlhdCI6MTczMzgxMDAyOX0.ubPvVz7YK4TPAdkd6VP9oNeGi6kmhwNlTUi13z4Fk7k";
//         const teamId = "2a1fe4f9b5a277442c83a8d2c4ffa528bd0c4a601744168b5d0058924e884404";
//         const name = process.env.REACT_APP_USER_NAME || "";
//         const eventIdString = process.env.REACT_APP_USER_EVENT_ID || "131858";
//         const eventId = Number.parseInt(eventIdString);
//         return Promise.resolve(new ClientCredential(user, userToken, teamId,  eventId));
//     }
//     RefreshToken(): Promise<ClientCredential> {
//         const user =  "2598-371159";
//         const userToken ="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiMjU5OC0zNzExNTkiLCJleHAiOjE3MzQ0MTQ4MjksImlhdCI6MTczMzgxMDAyOX0.ubPvVz7YK4TPAdkd6VP9oNeGi6kmhwNlTUi13z4Fk7k";
//         const teamId = "2a1fe4f9b5a277442c83a8d2c4ffa528bd0c4a601744168b5d0058924e884404";
//         const name = process.env.REACT_APP_USER_NAME || "";
//         const eventIdString = process.env.REACT_APP_USER_EVENT_ID || "131858";
//         const eventId = Number.parseInt(eventIdString);
//         return Promise.resolve(new ClientCredential(user, userToken, teamId,  eventId));
//     }
//     GetColors(): Promise<ApplicationColor> {
//         const topNavBackgroundColor = "#9B2735";
//         const topNavForegroundColor = "#FFFFFF";
//         return Promise.resolve(new ApplicationColor(topNavBackgroundColor, topNavForegroundColor));
//     }
//     FinishedLoading(isFinished: boolean): void {
//         console.log("Finished Loading" + isFinished );
//     }
//     SlidingGesture(enable: boolean): void {
//         console.log("Sliding Gesture:" + enable);
//     }
//     GetUnreadCount(UnreadCount: boolean): void {
//         console.log("UnreadCount:" + UnreadCount);
//     }
//     NavigationHandle(enable: boolean): void {
//         console.log("NavigationHandle:" + enable);
//     }
//     PushHandle(ReceiverUserId: String): void {
//         console.log("PushHandle", ReceiverUserId);
//     }
//     ToogleMenu(): void {
//         console.log("Menu Toggled !");
//     }
//      GetPlatformType(): Promise<string> {
//         return Promise.resolve("Mobile");
//     }
//     SendNewMessageUpdate(lastUpdatedAt: ChannelDataModel): void {
//         console.log("Message", lastUpdatedAt);
//     }
// }










