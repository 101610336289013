import {
  MessageList,
  MessageInput,
  Window,
  useChannelActionContext,
  Thread,
  useChatContext,
  StreamMessage,
  DefaultStreamChatGenerics,
  MessageInputProps,
  MessageToSend,
  useChannelStateContext,
  ChannelActionContextValue,

} from 'stream-chat-react';

import { MessagingChannelHeader } from '../../components';
import { useGiphyContext } from '../../context';
import "./ChannelInner.css"
import { useCallback, useEffect, useState } from 'react';
import HybridWebView from '../../hybridWebView/HybridWebView';
import Interop from '../../interfaces/Interop';

import CustomMessageRenderer from '../../CustomMessageList/CustomMessageRenderer';
import { ChatPageNavigation } from '../../models/ChatPageNavigation';
import { useAlertContext } from '../../context/AlertContext';  // Import the custom hook
import CustomAlert from '../CustomAlert/CustomAlert';
import { useBlockUnblockContext } from '../../context/BlockUnblock';
import { ClientCredential } from '../../models/ClientCredential';
import { StreamChat } from 'stream-chat';
import { useConnectUser } from '../../hooks';
import { useUserConnection } from '../../hooks/useUserConnection';
import { ChannelDataModel } from '../../models/ChannelDataModel';
import WebInterop from '../../implementation/WebInterop';
import { useChannelContext } from '../ChannelContext/ChannelContext';

function getTextArea() {
  const textArea = document.querySelector("textarea.rta__textarea.str-chat__textarea__textarea.str-chat__message-textarea") as HTMLElement;
  return textArea
}

function onTextareaInputKey(this: HTMLElement) {
  const defaultHeight = getTextAreaDefaultHeight(this);
  const maxHeight = parseInt(getTextAreaMaxDefaultHeight(this));

  this.style.height = defaultHeight + "px";
  if (this.scrollHeight < maxHeight) {
    this.style.overflowY = 'hidden';
    this.style.height = this.scrollHeight + "px";
  }
  else {
    this.style.overflowY = 'auto';
    this.style.height = maxHeight + 'px';
  }
}

function makeTextAreaHeightDynamic(textArea: HTMLElement) {
  textArea.style.removeProperty('height');
  textArea.style.height = textArea.scrollHeight + "px";
  textArea.style.overflowY = 'hidden';
}

function getTextAreaDefaultHeight(textArea: HTMLElement) {
  const computedStyle = getComputedStyle(textArea);
  const value = computedStyle.getPropertyValue("--str-chat__textarea-height");
  return value;
}

function getTextAreaMaxDefaultHeight(textArea: HTMLElement) {
  const computedStyle = getComputedStyle(textArea);
  const value = computedStyle.getPropertyValue("--str-chat__textarea-max-height");
  return value;
}

function resetTextAreaHeight(textArea: HTMLElement) {
  const defaultHeight = getTextAreaDefaultHeight(textArea);
  const height = parseInt(defaultHeight) + 2;
  textArea.style.height = height + 'px';
  textArea.style.overflowY = 'hidden';
}

function shouldSubmit(event: KeyboardEvent) {
  if (HybridWebView.IsWebView() === false) {
    return event.key === 'Enter' && !event.shiftKey;
  }
  return HybridWebView.IsWebView() === false && event.key === 'Enter';
}

function addKeyboardObserver() {

  if (window.visualViewport !== null) {
    window.visualViewport.onresize = _ => {

      const MINIMUM_KEYBOARD_HEIGHT = 300;

      if (window.visualViewport && ((window.visualViewport.height + MINIMUM_KEYBOARD_HEIGHT) < window.screen.height)) {
        // On keyboard open.
        const listNode = document.querySelector("div.str-chat__list") as HTMLElement;

        const ulNode = document.querySelector("ul.str-chat__ul") as HTMLElement;

        if (ulNode.scrollHeight > listNode.clientHeight) {
          const allListElements = document.querySelectorAll("li.str-chat__li");

          if (allListElements.length != 0) {
            //value = `${listNode.scrollHeight - listNode.clientHeight - 8} < ${listNode.scrollTop}`;

            const lastListElement = allListElements[allListElements.length - 1];
            lastListElement.scrollIntoView({ behavior: "smooth" });
          }
        }
      }
      else {
        // On keyboard close.
      }
    }
  }
}

function removeKeyboardObserver() {
  if (window.visualViewport !== null) {
    window.visualViewport.onresize = null;
  }
}
function showHideShareMediaIcon(value: boolean) {
  const shareMediaIcon = document.querySelector("div.str-chat__file-input-container") as HTMLElement;
  if (shareMediaIcon != null) {
    if (value) {
      shareMediaIcon.style.display = "none";
    }
    else {
      shareMediaIcon.style.display = "flex ";
    }
  }
}


export type ChannelInnerProps = {
  toggleMobile: () => void;
  interop: Interop;
  theme: string;
  platformType: string;
  languageCode: any;
  pageType: ChatPageNavigation;
};

const ChannelInner = (props: ChannelInnerProps) => {
  const { theme, interop, platformType, languageCode, toggleMobile, pageType } = props;
  const { giphyState, setGiphyState } = useGiphyContext();
  var { setActiveChannel, client } = useChatContext<DefaultStreamChatGenerics>();
  const { markRead } = useChannelActionContext<DefaultStreamChatGenerics>();
  const [isBlocked, setBlocked] = useState<boolean>(false);
  const [isMuted, setMuted] = useState<boolean>(false);
  const [isBlockIconVisible, setHideBlockIcon] = useState(false);
  const actions = ['delete', 'edit', 'react', 'reply'];
  const ThreadActions = ['delete', 'edit', 'react'];
  const [chatClient, setChatClient] = useState<StreamChat | null>(null);
  const { channel } = useChannelStateContext();

  var pushMessageId: any = pageType.PushMessageId;
  const { showAlert, alertMessage, setShowAlert, setAlertMessage } = useAlertContext();

  const { isUserBlocked, toggleBlock, blockedUsers } = useBlockUnblockContext(); // Use context to get blocked status
  const [blockedChannels, setBlockedChannels] = useState<{ [key: string]: boolean }>({});
  const [refreshToken, setRefreshToken] = useState<ClientCredential>();

  const { selectedChannel,setSelectedChannel } = useChannelContext(); // Get context
  const [totalUnreadCount, setTotalUnreadCount] = useState<number | null>(null);
  const apiKey = process.env.REACT_APP_STREAM_KEY || "";
  useEffect(() => {
    // setChatClient(client)
    setActiveChannel(channel)
  }, []);

  
  useEffect(() => {
    if (refreshToken) {

      console.log(refreshToken);
      const client = new StreamChat<DefaultStreamChatGenerics>(apiKey, {
        enableInsights: true,
        enableWSFallback: true,
      });

      const connectUser = async () => {
        await client?.connectUser({ id: refreshToken.UserId, eventId: refreshToken.EventId }, refreshToken.UserToken)
          .then((data) => {
            setChatClient(client);

            const channeldata = client.channel(channel!.type,channel.id);
            // Get the channel and set it in state
            setActiveChannel(channeldata);
            console.log('Token refreshed!' + data);
          })
          .catch((error: any) => {
            console.log('Failed to refresh token'+ error);
          });
      };
      connectUser();

    }
  }, [refreshToken]);


  const overrideSubmitHandler = async (message: MessageToSend<any>) => {

    try {
      let updatedMessage = { ...message };
      // Check if message has attachments and starts with '/giphy'
      if (message.attachments?.length && message.text?.startsWith('/giphy')) {
        updatedMessage.text = message.text.replace('/giphy', '');
      }

      // Check if Giphy is enabled
      if (giphyState) {
        updatedMessage.text = `/giphy ${message.text}`;
      }
      const newMessage = updatedMessage || message;
      const response = await channel?.sendMessage(newMessage);
      console.log('Message sent successfully', response);
      markRead();
      const textArea = getTextArea();
      resetTextAreaHeight(textArea);
      textArea.focus();

    } catch (error: any) {
      // Handle errors such as network issues or API errors
      if (error.code == 40) {
        try {
          await interop.RefreshToken().then(async credentialData=>{
            setRefreshToken(credentialData);
          });
        } catch (refreshError) {
          console.error('Error during refresh token process:', refreshError);
        }
      }
    }
    setGiphyState(false);
  };


  const members = Object.values(channel!.state.members || {}).filter(
    ({ user }) => user?.id !== client?.userID,
  );



  useEffect(() => {
    // Auto-hide the alert after 3 seconds
    const timer = showAlert
      ? setTimeout(() => {
        setShowAlert(false); // Hide the alert after 3 seconds
      }, 1000)
      : null;

    // Cleanup function
    return () => {
      if (timer) {
        clearTimeout(timer); // Clean up the timeout if it was set
      }
    };
  }, [showAlert, setShowAlert]);

  useEffect(() => {

    if (channel != null && client != null) {
      if (pushMessageId && typeof pushMessageId === 'string') {        //open thread directly
        client.getMessage(pushMessageId).then((data1: any) => {
          const replyCount = data1.message.reply_count
          var fetchedMessage = data1.message as any;
          if (replyCount > 0) {
            pageType.PushMessageId = "";
            console.log(pushMessageId);
            openThread(fetchedMessage);
          }
        });
      }
      var isAlreadyMember = false;
      if (channel.type == "public_with_membership") {

        if (members.length != 0) {
          for (let i = 0; i < members.length; i++) {
            if (members[i].user_id === client?.userID) {
              isAlreadyMember = true;
              break;
            }
          }
        }
        if (!isAlreadyMember) {
          channel.addMembers([{ user_id: client?.user?.id!!, channel_role: "channel_member" }])
        }
      }

      var mutedStatus = channel.muteStatus();
      setMuted(mutedStatus.muted);
      interop.PushHandle(channel.cid);
    }
  }, []);

  useEffect(() => {
    channel!.on('all', (event: any) => {
      if (event.type === 'all') {
        let value = false;
        if (event.text === "user blocked") {
          if (event.user.id === members[0].user_id!!) {
            setHideBlockIcon(true)
          }
          value = true
        } else {
          setHideBlockIcon(false)
        }
        setBlocked(value);
        showHideShareMediaIcon(value)
      }
      if(event.type === "message.read"){
    
        if(event.user?.id == client?.user?.id){
            if (selectedChannel != undefined || selectedChannel != null) {
              var isActiveChannel = selectedChannel?.id === event.channel_id;

              SetUnreadCountStatus().then(val =>{
                setTotalUnreadCount(val);
                if (val !== null) {
                  if(val == selectedChannel?.state.unreadCount){
                    isActiveChannel = true;
                  }
                  else{
                    isActiveChannel = false;
                  }
                  const channelData = new ChannelDataModel(event?.received_at,event.channel_type,isActiveChannel,event.cid);
                  SendNewMessageUpdate(interop,channelData);
                }
              });
          }
        }
      }
    });
  }, []);

  async function SendNewMessageUpdate(interop:Interop,data:ChannelDataModel) {
    interop.SendNewMessageUpdate(data);
  }

  async function SetUnreadCountStatus() {
      var totalUnreadCount = await getUnreadCount(client!,client?.userID!!);
      return totalUnreadCount;

}
  async function getUnreadCount(
    chatClient: StreamChat<DefaultStreamChatGenerics>,
    userId: string
  ) {
  
    const response = await chatClient.getUnreadCount(userId);
    return response.total_unread_count;
  }

  useEffect(() => {
    if (channel!.type === 'messaging') {

      var blockedState = false;

      if (channel!.data?.frozen !== undefined && channel!.data?.frozen == true) {
        blockedState = true
      }
      setBlocked(blockedState)
      showHideShareMediaIcon(blockedState)


      if (client?.userID === members[0].user_id!!)
        setHideBlockIcon(true)
      else
        setHideBlockIcon(false)
    }
  }, [isUserBlocked, blockedUsers]);


  useEffect(() => {

    if (channel !== null && channel !== undefined) {
      setBlocked(blockedChannels[channel!.id!!] ?? false); // Ensure the state is updated whenever blockedChannels changes

    }
  }, [blockedChannels]);


  (window as any).PerformBackAction = () => {
    toggleMobile();
  };
  const UpdateMutedUnMutedState = (value: boolean) => {
    setMuted(value);
  }
  const UpdateBlockUnblockState = (value: boolean) => {
    setBlocked(value);
    showHideShareMediaIcon(value)
  }


  useEffect(() => {
    addKeyboardObserver();
    const textArea = getTextArea();
    if (textArea) {
      makeTextAreaHeightDynamic(textArea);
      textArea.addEventListener('input', onTextareaInputKey, false);
    }

    return () => {
      if (textArea) {
        removeKeyboardObserver();
        textArea.removeEventListener('input', onTextareaInputKey);
      }
    };
  }, []);



  const { openThread: contextOpenThread } = useChannelActionContext<DefaultStreamChatGenerics>();

  const openThread = useCallback(
    (message: StreamMessage<DefaultStreamChatGenerics>, event?: React.BaseSyntheticEvent) => {
      contextOpenThread(message, event);
    },
    [contextOpenThread],
  );

  return (
    <>
      <Window>

        <MessagingChannelHeader theme={theme} toggleMobile={toggleMobile} isBlocked={isBlocked} isMuted={isMuted} interop={interop}
          isBlockIconVisible={isBlockIconVisible}
          languageCode={languageCode}
          UpdateBlockUnblockState={UpdateBlockUnblockState} UpdateMutedUnMutedState={UpdateMutedUnMutedState} platformType={platformType} />
        {<MessageList messageActions={actions} closeReactionSelectorOnClick={true} Message={CustomMessageRenderer} onlySenderCanEdit={true}
          openThread={openThread} />}
        {showAlert && <CustomAlert message={alertMessage} />}

        <MessageInput
          disableMentions={(channel !== null && channel!.type === "public_with_membership") ? false : true}
          shouldSubmit={shouldSubmit}
          overrideSubmitHandler={overrideSubmitHandler}
          disabled={isBlocked}
          hideSendButton={isBlocked}
          additionalTextareaProps={{ onPaste: undefined, placeholder: isBlocked ? languageCode['Blocked'] : languageCode['Type your message'] }} />
      </Window>
      <Thread
        additionalMessageInputProps={{ disableMentions: (channel !== null && channel!.type === "public_with_membership") ? false : true }}
        Message={CustomMessageRenderer} messageActions={ThreadActions} />

    </>
  );
};

export default ChannelInner;
