import type { MouseEventHandler } from 'react';
import { ChannelList, ChannelListProps } from 'stream-chat-react';
import { MessagingChannelListHeader, MessagingChannelPreview } from '../index';
import { useThemeContext } from '../../context';
import { useEffect } from 'react';
import { useChatContext, CustomQueryChannelParams } from 'stream-chat-react';
import { Channel,Event, DefaultGenerics } from 'stream-chat';

import { Dispatch, SetStateAction, } from "react";
import { DefaultStreamChatGenerics } from "stream-chat-react";


type MessagingSidebarProps = {
  channelListOptions: {
    filters: ChannelListProps['filters'];
    sort: ChannelListProps['sort'];
    options: ChannelListProps['options'];
  };
  onClick: () => void;
  onCreateChannel: () => void;
  onPreviewSelect: MouseEventHandler;
  activeChannel?: Channel<DefaultGenerics> | null;
  channelQueryParams: {
    eventId: number;
  };
  platformType:string
  languageCode: any;

};

const MessagingSidebar = ({
  channelListOptions,
  onClick,
  onCreateChannel,
  activeChannel,
  channelQueryParams,
  platformType,
  languageCode
}: MessagingSidebarProps) => {
  const { themeClassName } = useThemeContext();
  const { client, setActiveChannel } = useChatContext();

  useEffect(() => {
    if (activeChannel != undefined || activeChannel != null) {
      setActiveChannel(activeChannel);
      onClick();
      activeChannel = null;
    }
  }, [activeChannel]);

  const customQueryChannels = async ({
    setChannels
  }: CustomQueryChannelParams) => {

    const UnblockChannelFilter = { type: 'messaging', members: { $in: [client.user?.id!] },
    hidden: false, eventId: channelQueryParams.eventId };

    const blockChannelFilter = { type: 'messaging', members: { $in: [client.user?.id!] },
     hidden: true, eventId: channelQueryParams.eventId };
  
    const blockedChannels = await client.queryChannels(
      blockChannelFilter,
      channelListOptions.sort,
      channelListOptions.options
    );

    const unblockedChannels = await client.queryChannels(
      UnblockChannelFilter,
      channelListOptions.sort,
      channelListOptions.options
    );

    let newUnBlockedChannels = unblockedChannels.filter(ele => {
      return ele.state.messages.length !== 0
    });

    
    const publicwithoutChannelFilter = { type: 'public_without_membership',eventId: channelQueryParams.eventId};
    const publicwithoutChannels = await client.queryChannels(publicwithoutChannelFilter,  channelListOptions.sort, { watch: true, limit: 30});


    const publicChannelFilter = { type: 'public_with_membership',eventId: channelQueryParams.eventId};
    const publicChannels = await client.queryChannels(publicChannelFilter,  channelListOptions.sort, { watch: true, limit: 30});
   
    const newChannels = ([...newUnBlockedChannels, ...blockedChannels, ...publicChannels]);
    

    let result = newChannels.sort((a, b) => {
      const lastMessageA = a.data?.last_message_at as string;
      const lastMessageB = b.data?.last_message_at as string;
  
      if (lastMessageA !== undefined && lastMessageB !== undefined) {
          return lastMessageB.localeCompare(lastMessageA);
      }
      
      // If only `b` is defined, `b` should come before `a`
      if (lastMessageB !== undefined) return 1;
      // If only `a` is defined, `a` should come before `b`
      if (lastMessageA !== undefined) return -1;
  
      // If both are undefined, maintain original order
      return 0;
  });
  
    // Filter chat and channels based on user role as moderator
    const moderatorChannels = ([ ...publicChannels, ...publicwithoutChannels]);
    if(client.user?.role === 'moderator'){
      setChannels(moderatorChannels);
    } else {
      setChannels(result);
    }
  };

  const customOnMessageNew = async (setChannels:Dispatch<SetStateAction<Channel<DefaultStreamChatGenerics>[]>>, event:Event<DefaultStreamChatGenerics>) => {
    const eventChannel = event.channel;
  
    if (eventChannel!= undefined 
      && eventChannel.eventId==channelQueryParams.eventId)
    {   
    try {
      const newChannel = client.channel(eventChannel.type, eventChannel.id);
      await newChannel.watch();
      setChannels((channels) => [newChannel, ...channels]);
    } catch (error) {
      console.log(error);
    }
  }
  };
   return (
    <div
      className={`str-chat messaging__sidebar ${themeClassName} show`}
      id='mobile-channel-list'>

      <MessagingChannelListHeader onCreateChannel={onCreateChannel} platformType = {platformType} languageCode={languageCode}
      />
      {/* <div onClick={onClick}> */}
      <ChannelList setActiveChannelOnMount={false} customQueryChannels={customQueryChannels }
      onMessageNew={customOnMessageNew} allowNewMessagesFromUnfilteredChannels={false}
          Preview={(props) => <MessagingChannelPreview {...props} onClick={onClick} setActiveChannel={setActiveChannel} languageCode={languageCode}/>}
      />
      {/* </div> */}
    </div>
  );
};

export default MessagingSidebar;


