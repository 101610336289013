import { DateSeparator } from 'stream-chat-react';
import type { DateSeparatorProps } from 'stream-chat-react';

interface DateSeparatorCustomProps extends DateSeparatorProps {
    languageCode: any; // Add languageCode prop
}

const DateSeparatorCustom = ({ date, languageCode }: DateSeparatorCustomProps) => {
    const getDay = (day: number) => {
        
        switch (day) {
            case 1:
                return languageCode['Monday'] || 'Monday'; 
            case 2:
                return languageCode['Tuesday'] || 'Tuesday';
            case 3:
                return languageCode['Wednesday'] || 'Wednesday';
            case 4:
                return languageCode['Thursday'] || 'Thursday';
            case 5:
                return languageCode['Friday'] || 'Friday';
            case 6:
                return languageCode['Saturday'] || 'Saturday';
            case 0:
                return languageCode['Sunday'] || 'Sunday';
            default:
                return "";
        }
    }

    function formatDate(date: Date) {
        const day = date.getDate();
        const dayOfWeek = getDay(date.getDay());
        const monthName = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();

        return `${dayOfWeek}, ${day} ${monthName}, ${year}`;
    }

    return (
        <DateSeparator formatDate={formatDate} date={date} position='center' />
    );
}

export default DateSeparatorCustom;
