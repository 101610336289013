import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import HybridWebView from './hybridWebView/HybridWebView';
import NativeInterop from './implementation/NativeInterop';

import WebInterop from './implementation/WebInterop';
import Interop from './interfaces/Interop';
import BootComponent from './BootComponent';

const apiKey = process.env.REACT_APP_STREAM_KEY || "";

//const noChannelNameFilter = urlParams.get('no_channel_name_filter') || true;
//const skipNameImageSet = urlParams.get('skip_name_image_set') || true;

let interop: Interop;

if(HybridWebView.IsWebView()) {
  interop = new NativeInterop();
}
else {
  interop = new WebInterop();
}

(window as any).setTextSize = (size: Number) => {
  document.documentElement.style.setProperty('--text-size', `${size}%`);
};

const target_origin = process.env.REACT_APP_TARGET_ORIGIN!;

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container!);
root.render(
  <React.StrictMode>
    <BootComponent apiKey={apiKey} targetOrigin={target_origin} interop={interop} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     dsn: 'https://cfe9b30508b44e40908da83aee0743e9@o389650.ingest.sentry.io/5556314',
//     integrations: [new Sentry.BrowserTracing()],
//     tracesSampleRate: 1.0,
//   });
// }

//const targetOrigin = urlParams.get('target_origin') || (process.env.REACT_APP_TARGET_ORIGIN as string);