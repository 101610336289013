import { Avatar, DefaultStreamChatGenerics, useChatContext } from 'stream-chat-react';
import './AvatarChannelView.css';
import { CircularUser } from '../../assets/CircularUser';
import { Channel } from 'stream-chat';
import { StreamChatGenerics } from '../../types';
import { ChatPageType } from '../../enums/ChatPageType';

function getNameInitials(
  username:string,
) {
 
  let names = "";
  if (username && username.trim() !== "") {
      const tempUserName = username.trim().split(' ');
      names = tempUserName[0].charAt(0);
      if (tempUserName.length > 1) {
          names += tempUserName[tempUserName.length - 1].charAt(0);
      }
  }
  return names.toUpperCase();
}

export const AvatarChannelView = ({data,pageType}: { data:Channel,pageType:ChatPageType}) => {
  const { client } = useChatContext<DefaultStreamChatGenerics>();
  let content: JSX.Element;
  const members: any = Object.values(data.state.members || {}).filter(
    (member) => member.user?.id !== client?.user?.id,
  );
  if (data.type == "messaging") {
    var imageURL: string= members[0]?.user.photourl||undefined;
    if(imageURL ===undefined || imageURL === null) {
      var name = getNameInitials(members[0]?.user!.name!!);
       content = <h4>{name}</h4>
    }
    else {
      content = <Avatar image={imageURL} />;
    }
  }
  else {
    content = <CircularUser />
  }
  return (
    <div className={
      `${(pageType === ChatPageType.ChannelDetail && data.type === "messaging") ||
        (pageType === ChatPageType.ChannelListing && data.type !== "messaging")
        ? 'avatar-group channel_header'
        : 'avatar-group channel_listing'}
      ${pageType === ChatPageType.ChannelListing && data.type !== "messaging" ? 'background-transparent' : ''}`
    }>
      {content}
    </div>
  );
};
export default AvatarChannelView;
