import React from 'react';
import './CustomAlert.css';

type CustomAlertProps = {
  message: string;
};
const CustomAlert: React.FC<CustomAlertProps> = ({ message }) => {
  return (
      <div className="custom-alert">
        <p>{message}</p>
      </div>
  );
};

export default CustomAlert;

