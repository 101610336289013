import React, { createContext, useState, useContext, ReactNode } from 'react';

// Define types for the alert context
type AlertContextType = {
  showAlert: boolean;
  alertMessage: string;
  setShowAlert: (show: boolean) => void;
  setAlertMessage: (message: string) => void;
};

// Create the context with default values
const AlertContext = createContext<AlertContextType | undefined>(undefined);

// Context Provider component
export const AlertContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  return (
    <AlertContext.Provider value={{ showAlert, alertMessage, setShowAlert, setAlertMessage }}>
      {children}
    </AlertContext.Provider>
  );
};

// Custom hook to use the AlertContext
export const useAlertContext = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlertContext must be used within an AlertContextProvider');
  }
  return context;
};
