export class ClientCredential {

    UserId: string;

    UserToken: string;

    TeamId: string;


    EventId: number;

    constructor(userId: string, userToken: string, teamId: string,  EventId: number) {
        this.UserId = userId;
        this.UserToken = userToken;
        this.TeamId = teamId;
        this.EventId = EventId;
    }
}