import type { ThreadHeaderProps } from 'stream-chat-react';

import './MessagingThreadHeader.css';

import { CloseThreadIcon } from '../../assets';

interface MessagingThreadHeaderProps extends ThreadHeaderProps {
  languageCode: any; // Adjust the type of languageCode if needed
}
const MessagingThreadHeader = ({ closeThread, thread, languageCode }: MessagingThreadHeaderProps) => {
  const getReplyCount = () => {
  
    if (!thread?.reply_count) return '';
    const count = thread.reply_count;
    if (count === 1) return `${count} ${languageCode.Reply || 'reply'}`;
   return `${count} ${languageCode.Replies || 'Replies'}`;
  };
  return (
    <div className='custom-thread-header'>
      <div className='custom-thread-header__left'>
        <p className='custom-thread-header__left-title'> {languageCode.Thread}</p>
        <p className='custom-thread-header__left-count'>{getReplyCount()}</p>
      </div>
      <CloseThreadIcon closeThread={closeThread} />
    </div>
  );
};

export default MessagingThreadHeader;
