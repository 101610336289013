
export const ChatMessage = ({ isBlocked, isAlreadyMember, isTyping, latestMessage, languageCode }
  : { isBlocked: Boolean, isAlreadyMember: Boolean, isTyping: Boolean, latestMessage: any, languageCode: any }) => {
    let displayMessage;
  if (isBlocked) {
    displayMessage = languageCode['Blocked Participant'];
  } else {
    displayMessage = isAlreadyMember
      ? (isTyping ? languageCode['typing...'] : latestMessage)
      : languageCode['click to join the channel'];
  }

  return (
    <div>
      {displayMessage}
    </div>
  );
};

export default ChatMessage;
